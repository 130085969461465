import { RED } from 'styles/colors'

export const styles = {
  container: {
    width: '100%'
  },
  internalContainer: {
    width: '100%',
    maxWidth: '961px',
    minHeight: '85vh',
    margin: 'auto',
    padding: 30,
  },
  title: {
    fontSize: 33,
    fontWeight: 700,
    textAlign: 'center',
    color: RED,
    display: 'block'
  },
  iconsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: 30,
    minHeight: 400
  },
  icon: {
    color: RED,
    fontSize: 80
  }
}
