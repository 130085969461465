/* eslint max-len: 0 */
import React from 'react'
import {
  COMPANY, COMPANY_NUMBER, ICO_WEBSITE, GOOGLE_PRIVACY, GDPR_WEBSITE, MAILTO, LAST_PRIVACY_UPDATE
} from '../constants'
import { styles } from '../styles'

const Privacy = () => (
  <div style={styles.container}>
    <div style={styles.internalContainer}>
      <div style={styles.textContainer}>
        <p style={styles.title}>PRIVACY POLICY</p>
        <div style={styles.paragraphText}>Use of this website and related services (the &quot;Website&quot;) by you constitutes acceptance of this privacy policy (“Privacy Policy”). If you do not wish to accept this Privacy Policy please leave this Website immediately.</div>
        <div style={styles.paragraphText}>We take data protection seriously and the purpose of this Privacy Policy, together with our Terms of Use and Cookie Policy, is to explains when and why we collect personal information about people who visit our website, how we use it, the conditions under which we may disclose it to others and how we keep it secure.</div>
        <div style={styles.paragraphText}>
The rules on processing of personal data are set out in
          {' '}
          <a href={GDPR_WEBSITE} style={styles.paragraphAnchor} target="_blank" data-cy="privacy-link-gdpr" rel="noopener nofollow noreferrer"> the General Data Protection Regulation (the “GDPR”).</a>
        </div>


        <div style={styles.paragraphTitle}>1. Who we are</div>
        <div style={styles.paragraphText}>
          {COMPANY}
          {' '}
(Company number
          {' '}
          {COMPANY_NUMBER}
) is an IT company specialised in design, development and management of websites / web / mobile apps.
        </div>

        <div style={styles.paragraphTitle}>2. Data Controller</div>
        <div style={styles.paragraphText}>
For general data protection regulation purposes, the “data controller” means the person or organisation who decides the purposes for which and the way in which any personal data is processed. 
{COMPANY} 
is the data controller of this Website.
        </div>

        <div style={styles.paragraphTitle}>3. How to contact us</div>
        <div style={styles.paragraphText}>If you have any questions about this Privacy Policy, please contact us by email.</div>

        <div style={styles.paragraphTitle}>4. Complaints</div>
        <div style={styles.paragraphText}>
If you feel that your personal data has been processed in a way that does not meet the GDPR, you have a specific right to lodge a complaint with your local supervisory authority, the Information Commissioner’s Office (ICO). You can find
          {' '}
          <a href={ICO_WEBSITE} style={styles.paragraphAnchor} target="_blank" data-cy="privacy-link-ico" rel="noopener nofollow noreferrer">ICO&apos;s details here.</a>
        </div>

        <div style={styles.paragraphTitle}>5. What information we collect, how we use it and how long we retain your data</div>
        <div style={styles.paragraphSubTitle}>5.1 If you have requested our services</div>
        <div style={styles.paragraphText}>We run a Business-to-Business web development and consultancy service.</div>
        <div style={styles.paragraphText}>Your company name, email address, postal address (your company or card billing address) and amount paid are collected by us and stored in our database.</div>
        <div style={styles.paragraphText}>We review your information before moving forward with the subscription.</div>
        <div style={styles.paragraphText}>You should provide only company information (business name, generic business email such as info@example.com and company address).</div>
        <div style={styles.paragraphText}>We reserve the right to stop the process if you are providing fake or personal information and hold you information for 1 year for security purposes and to give you the time to amend your information if you want to move the subscription process forward. After one year, we will delete the information.</div>
        <div style={styles.paragraphText}>If the registration information is considered legitimate, we will send you an invoice.</div>
        <div style={styles.paragraphText}>When the invoice is paid, your name, payment and security details may be held by the payment processor if you used such payment service.</div>
        <div style={styles.paragraphText}>We do not use this data for purposes other than processing your registration, and company accounting — which may involve sharing data with our accountant or HMRC.</div>
        <div style={styles.paragraphText}>We are obliged to keep records relating to financial transactions for at least six years following the end of the accounting period in which the transaction took place. We destroy our records after this point; if you require information on payment processors’ policies, please contact them directly.</div>

        <div style={styles.paragraphSubTitle}>5.2 If you contact us via email</div>
        <div style={styles.paragraphText}>Your name, email address and message will be accessible to our team and may be forwarded to the person or people in the organisation best equipped to respond. We adhere to strict internal privacy policies which comply with the GDPR.</div>

        <div style={styles.paragraphSubTitle}>5.3 If you visit our website</div>
        <div style={styles.paragraphText}>For security purposes, we collect your IP address when you visit our pages or perform actions. Your IP is completely anonymised as they are stored in hashed form. We reserve the right to block access to your IP if illegitimate use such as hacking attempts is acknowledged.</div>
        <div style={styles.paragraphText}>We use Google Analytics to store information about how visitors use our website so that we may make improvements and give visitors a better user experience.</div>
        <div style={styles.paragraphText}>Google Analytics stores information such as what pages you visit, how long you are on the site, how you got here, what you click on, and information about your web browser. These cookies do not store any personal information about you eg name, address etc and we do not share the data. IP addresses are masked (only a portion is stored) and personal information is only reported in aggregate. We do not allow Google to use or share our analytics data for any purpose besides providing us with analytics information, and we recommend that any user of Google Analytics does the same.</div>
        <div style={styles.paragraphText}>
You can view
          {' '}
          <a href={GOOGLE_PRIVACY} style={styles.paragraphAnchor} target="_blank" data-cy="privacy-link-google" rel="noopener nofollow noreferrer">Google&apos;s privacy policy here.</a>
        </div>
        <div style={styles.paragraphText}>If you’re unhappy with the idea of sharing the fact you visited our sites (and any other sites) with Google, you can install the official browser plugin for blocking Google Analytics.</div>
        <div style={styles.paragraphText}>Other than for accounting purposes, we retain your personal data until such a time as you unsubscribe or ask us to remove your details.</div>
        <div style={styles.paragraphText}>Your personal information is never shared externally, or used for purposes other than the above.</div>

        <div style={styles.paragraphTitle}>6. Legal basis for processing</div>
        <div style={styles.paragraphText}>In contacting us, you are consenting to the processes as described on this page. The legal basis is GDPR  6(1)(a) – Consent of the data subject.</div>
        <div style={styles.paragraphText}>Processing of your data on the legal basis of GDPR 6(1)(c) – Processing is necessary for compliance with a legal obligation. We need to process and retain your data in order to comply with the law regarding company and charity accounting.</div>

        <div style={styles.paragraphTitle}>7. Transferring your information outside of Europe</div>
        <div style={styles.paragraphText}>As part of the services offered to you through this website, the information which you give to us may be transferred to countries outside the European Union (“EU”). For example, some of our third-party providers may be located outside of the EU. The right security measures are taken so that your privacy rights continue to be protected as outlined in this policy. By submitting your personal data, you’re agreeing to this transfer, storing or processing. Where our third-party supplies are in the US we have ensured that their services fall under the Privacy Shield whereby participating companies are deemed to have adequate protection and therefore facilitate the transfer of information from the EU to the US.</div>
        <div style={styles.paragraphText}>If you use our services while you are outside the EU, your information may be transferred outside the EU to give you those services.</div>

        <div style={styles.paragraphTitle}>8. Security precautions in place about data collected</div>
        <div style={styles.paragraphText}>You may instruct us to provide you with any personal information we hold about you. Provision of such information will be subject to:</div>
        <div style={styles.paragraphText}>When you give us personal information, we take steps to make sure that it’s treated securely.</div>
        <div style={styles.paragraphText}>We do not store sensitive information such as credit or debit card details.</div>
        <div style={styles.paragraphText}>Non-sensitive details (your name, surname, email address etc.) are sent normally over the Internet.</div>
        <div style={styles.paragraphText}>We do our best to make information sharing and storage secure by using SSL certificates for our websites, TLS/SSL on our email accounts, password and IP hashing and other security measures.</div>
        <div style={styles.paragraphText}>Despite our efforts, we cannot guarantee the security of any information you transmit to us, and you do so at your own risk.</div>
        <div style={styles.paragraphText}>Where we have given (or where you have chosen) a password which enables you to access certain parts of our websites, you are responsible for keeping this password confidential. The password should have a minimum of 12 characters and contain at least one uppercase, one lowercase and one numeric character. We ask you not to share your password with anyone.</div>

        <div style={styles.paragraphTitle}>9. Your rights</div>
        <div style={styles.paragraphSubTitle}>9.1 Right to be informed</div>
        <div style={styles.paragraphText}>
The purpose of this Privacy Policy is to inform you about the data processing activities we carry out. We hope that this privacy policy is concise, easy to ready and easy to understand. If you have questions or concerns about this Privacy Policy please
          {' '}
          <a href={MAILTO} style={styles.paragraphAnchor} data-cy="terms-link-aiemail" rel="noopener nofollow noreferrer">contact us.</a>
        </div>

        <div style={styles.paragraphSubTitle}>9.2 Right to be Access</div>
        <div style={styles.paragraphText}>
You have the right to contact us at any time to ask to see what personal data we hold about you. To request this, please
          {' '}
          <a href={MAILTO} style={styles.paragraphAnchor} data-cy="terms-link-aiemail" rel="noopener nofollow noreferrer">contact us.</a>
        </div>

        <div style={styles.paragraphSubTitle}>9.3 Right to rectification</div>
        <div style={styles.paragraphText}>
You may request that we rectify the personal data that we hold about you if you acknowledge them to be inaccurate or incomplete. To request this, please
          {' '}
          <a href={MAILTO} style={styles.paragraphAnchor} data-cy="terms-link-aiemail" rel="noopener nofollow noreferrer">contact us.</a>
        </div>

        <div style={styles.paragraphSubTitle}>9.4 Right to erasure</div>
        <div style={styles.paragraphText}>
You may request that we destroy the personal data that we hold about you, provided that there is no legitimate reason for us continuing to hold it, if it does not relate to financial transactions or is unlikely to be of any legal or practical purpose in the future. To request this, please
          {' '}
          <a href={MAILTO} style={styles.paragraphAnchor} data-cy="terms-link-aiemail" rel="noopener nofollow noreferrer">contact us.</a>
        </div>

        <div style={styles.paragraphSubTitle}>9.5 Right to restriction of processing</div>
        <div style={styles.paragraphText}>
You may request that we restrict the processing of personal data if you acknowledge that your data are inaccurate or the processing of the data is unlawful, illegitimate or no longer needed. To request this, please
          {' '}
          <a href={MAILTO} style={styles.paragraphAnchor} data-cy="terms-link-aiemail" rel="noopener nofollow noreferrer">contact us.</a>
        </div>

        <div style={styles.paragraphSubTitle}>9.6 Right to data portability</div>
        <div style={styles.paragraphText}>
It is also your right to receive the personal data which you have given to us, in a structured, commonly used and machine-readable format and have the right to transmit that data to another controller without delay from the current controller if the processing is based on consent or on a contract, and the processing is carried out by automated means. To request this, please
          {' '}
          <a href={MAILTO} style={styles.paragraphAnchor} data-cy="terms-link-aiemail" rel="noopener nofollow noreferrer">contact us.</a>
        </div>

        <div style={styles.paragraphSubTitle}>9.7 Right to object</div>
        <div style={styles.paragraphText}>
You have the right to object to processing based on legitimate interests, the performance of a task in the public interest or the exercise of official authority (including profiling), direct marketing (including profiling) and processing for scientific/historic research or statistics. To request this, please
          {' '}
          <a href={MAILTO} style={styles.paragraphAnchor} data-cy="terms-link-aiemail" rel="noopener nofollow noreferrer">contact us.</a>
        </div>

        <div style={styles.paragraphSubTitle}>9.8 Rights relating to automated decision making and profiling</div>
        <div style={styles.paragraphText}>You have the right not to be subject to a decision when it is based on automated processing and it produces a legal effect or a similarly significant effect on the individual.</div>
        <div style={styles.paragraphText}>
We do not perform automated decisiong making and profiling with your data. If you have any concerns or do you want to know more about it, please
          {' '}
          <a href={MAILTO} style={styles.paragraphAnchor} data-cy="terms-link-aiemail" rel="noopener nofollow noreferrer">contact us.</a>
        </div>

        <div style={styles.paragraphTitle}>10. Cookies</div>
        <div style={styles.paragraphText}>Our website use cookies. Please read our Cookie Policy to find out more.</div>

        <div style={styles.paragraphTitle}>11. Other websites</div>
        <div style={styles.paragraphText}>Our website contains links to other websites. This privacy policy only applies to this website so when you link to other websites you should read their own privacy policies.</div>


        <div style={styles.lastUpdate}>
        Last updated
          {' '}
          {LAST_PRIVACY_UPDATE}
        </div>
      </div>
    </div>
  </div>
)

export default Privacy
