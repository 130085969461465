import {
  GREY, BLUE
} from 'styles/colors'

export const styles = {
  header: {
    position: 'fixed',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${GREY}`,
    backgroundColor: BLUE,
    zIndex: 1100,
    left: 0,
    top: 0,
    height: 60
  },
  headerContainer: {
    width: '100%',
    maxWidth: '961px',
    display: 'flex',
    height: 60,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    marginLeft: 10,
    width: 80
  },
  image: {
    width: '100%'
  }
}
