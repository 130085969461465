import { RED, BLUE, WHITE } from 'styles/colors'

export const styles = {
  container: {
    width: '100%'
  },
  internalContainer: {
    width: '100%',
    maxWidth: '961px',
    margin: 'auto',
    padding: 30,
  },
  title: {
    fontSize: 33,
    fontWeight: 700,
    marginTop: 20,
    textAlign: 'center',
    color: RED,
    display: 'block'
  },
  buyCds: {
    fontSize: 24,
    fontWeight: 700,
    marginTop: 50,
    textAlign: 'center',
    color: RED,
    display: 'block'
  },
  cdTitle: {
    fontSize: 24,
    fontWeight: 700,
    marginTop: 50,
    marginBottom: 20,
    textAlign: 'center',
    color: BLUE,
    display: 'block'
  },
  paragraph: {
    fontSize: 14,
    textAlign: 'center'
  },
  cdWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 10,
  },
  cdFront: {
    display: 'flex',
    maxWidth: 400,
    flexWrap: 'wrap',
  },
  cover: {
    maxWidth: 280,
    width: '100%',
    margin: 20
  },
  imageLink: {
    width: 100,
    margin: 10
  },
  image: {
    width: '100%'
  },
  cdRetro: {
    margin: 20,
    width: '100%',
    maxWidth: 400,
  },
  btnWrapper: {
    width: '100%',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  btn: {
    width: 150,
    borderRadius: 40,
    padding: 10,
    background: RED,
    textAlign: 'center',
    color: WHITE
  },
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: 15,
    height: 0,
    marginBottom: 20
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%'
  },
  lyrics: {
    textAlign: 'center',
    fontStyle: 'italic',
    display: 'block',
    width: '100%'
  },
  marginB20: {
    marginBottom: 20
  }
}
