import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Image } from 'semantic-ui-react'
import { LINK_HOMEPAGE } from 'controller/constants'
import Logo from 'assets/img/padraig-logo.png'
import { styles } from './styles'

const Header = () => (
  <header style={styles.header} data-cy="header">
    <div style={styles.headerContainer}>
      <div style={styles.logo}>
        <Link to={LINK_HOMEPAGE}><Image src={Logo} style={styles.image} /></Link>
      </div>
    </div>
  </header>
)

export default withRouter(Header)
