import { RED, BLUE } from 'styles/colors'

export const styles = {
  container: {
    width: '100%'
  },
  internalContainer: {
    width: '100%',
    maxWidth: '961px',
    margin: 'auto',
    padding: 30,
  },
  title: {
    fontSize: 33,
    fontWeight: 700,
    marginTop: 20,
    textAlign: 'center',
    color: RED,
    display: 'block'
  },
  buyCds: {
    fontSize: 24,
    fontWeight: 700,
    marginTop: 50,
    textAlign: 'center',
    color: RED,
    display: 'block'
  },
  bookTitle: {
    fontSize: 24,
    fontWeight: 700,
    marginTop: 50,
    marginBottom: 20,
    textAlign: 'center',
    color: BLUE,
    display: 'block'
  },
  paragraph: {
    fontSize: 14,
    textAlign: 'center'
  },
  bookWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 10,
  },
  cdFront: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    flexWrap: 'wrap',
  },
  cover: {
    maxWidth: 280,
    width: '100%',
    margin: 20
  },
  imageLink: {
    width: 100,
    margin: 10
  },
  image: {
    width: '100%'
  },
  cdRetro: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: 500,
    fontSize: 14,
    fontWeight: 600
  }
}
