import { WHITE, GREY, BLACK } from 'styles/colors'
import { FONT_FAMILY } from 'styles/fonts'

export const styles = {
  container: {
    paddingTop: 60,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  containerCookieBar: {
    background: GREY,
    color: WHITE,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100000,
    padding: 10
  },
  internalContainer: {
    width: '100%',
    maxWidth: 1300
  },
  textContainer: {
    width: '100%',
    maxWidth: 777,
    margin: 'auto',
    padding: 10,
    marginTop: 50
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    color: BLACK,
    display: 'block'
  },
  lastUpdate: {
    fontSize: 12,
    marginTop: 25,
    marginBottom: 50,
    textAlign: 'center',
    color: BLACK,
    display: 'block'
  },
  paragraphTitle: {
    fontWeight: '700',
    color: BLACK,
    marginTop: 10,
    marginBottom: 10
  },
  paragraphSubTitle: {
    fontWeight: '600',
    color: BLACK,
    marginTop: 10,
    marginBottom: 10
  },
  paragraphText: {
    textAlign: 'justify',
    color: BLACK,
    lineHeight: '20px'
  },
  paragraphAnchor: {
    color: BLACK,
    fontWeight: 600,
    cursor: 'pointer'
  },
  checkboxes: {
    padding: 15,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  triangle: {
    zIndex: 1,
    position: 'fixed',
    bottom: -8,
    left: -36,
    width: 0,
    height: 0,
    borderLeft: '55px solid transparent',
    borderRight: '55px solid transparent',
    borderBottom: `55px solid ${BLACK}`,
    transform: 'rotate(-135deg)'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  button: {
    width: 150,
    backgroundColor: WHITE,
    color: BLACK,
    fontSize: 12,
    padding: 10,
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    borderRadius: 8,
    textAlign: 'center',
    lineHeight: '1.2em',
    margin: 5
  },
  message: {
    textAlign: 'center',
    color: BLACK,
  },
  icon: {
    zIndex: 1,
    color: WHITE,
    fontSize: 27,
    position: 'fixed',
    bottom: 7,
    left: 7,
    cursor: 'pointer'
  }
}
