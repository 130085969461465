/* eslint max-len:0 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from 'semantic-ui-react'
import Fade from 'react-reveal/Fade'
import Amazon from 'assets/img/amazon_black.png'
import CdBaby from 'assets/img/cdbaby_black.png'
import {
  BLANK, YOUTUBE_NOCOOKIE, REL, AMAZON_TB, CDBABY_TB, VIDEOS_TB, TITLES_TB
} from 'controller/constants'
import { styles } from './styles'

const TheButterfly = () => {
  const { t } = useTranslation()

  return (
    <div style={styles.container}>
      <div style={styles.internalContainer}>
        <Fade delay={300}>
          <p style={styles.title}>The Butterfly</p>
        </Fade>

        <Fade delay={300}>
          <div style={styles.buyCds}>
            {t('buyAlbums')}
          </div>
          <div style={styles.cdWrapper}>
            <a href={AMAZON_TB} rel={REL} target={BLANK} style={styles.imageLink}>
              <Image src={Amazon} alt="Amazon" style={styles.image} />
            </a>
            <a href={CDBABY_TB} rel={REL} target={BLANK} style={styles.imageLink}>
              <Image src={CdBaby} alt="CdBaby" style={styles.image} />
            </a>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_TB[0]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p style={styles.lyrics}>Come back to me with all your heart,</p>
              <p style={styles.lyrics}>Don&apos;t let fear keep us apart.</p>
              <p style={styles.lyrics}>Don&apos;t fade away like the morning cloud,</p>
              <p style={styles.lyrics}>Or Like the early morning dew.</p>
              <p style={styles.lyrics}>Set my love deep in your heart,</p>
              <p style={styles.lyrics}>Seal your love with a wedding band.</p>
              <p style={styles.lyrics}>Though health and life may fade away,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Love lives eternal, beyond the grave.</p>

              <p style={styles.lyrics}>We won&apos;t ride horses anymore,</p>
              <p style={styles.lyrics}>Or praise their speed and power at rein.</p>
              <p style={styles.lyrics}>I love you with all my heart,</p>
              <p style={styles.lyrics}>As fragrance of the lily bloom.</p>
              <p style={styles.lyrics}>Hear my voice speak to your heart,</p>
              <p style={styles.lyrics}>In the gentle calm of the mountain heights.</p>
              <p style={styles.lyrics}>Come, I will hear you like a prayer,</p>
              <p style={styles.lyrics}>With joy, in shade of the evergreen.</p>

            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_TB[0]} title={TITLES_TB[0]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_TB[1]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p style={styles.lyrics}>A rose grows in the garden,</p>
              <p style={styles.lyrics}>Planted there for me,</p>
              <p style={styles.lyrics}>To bloom into a flower</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>For all the world to see.</p>

              <p style={styles.lyrics}>All yours, all yours</p>
              <p style={styles.lyrics}>My love,</p>
              <p style={styles.lyrics}>My life,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>All yours.</p>

              <p style={styles.lyrics}>I praise the word that made you,</p>
              <p style={styles.lyrics}>And formed you in my soul.</p>
              <p style={styles.lyrics}>The grace of every petal,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>The truth true love unfolds.</p>

              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>All yours ....</p>

              <p style={styles.lyrics}>O&apos; Mary, gentle woman</p>
              <p style={styles.lyrics}>My bouquet pleases you</p>
              <p style={styles.lyrics}>To crown you full of favour</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Love&apos;s garland honours you.</p>

              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>All yours ....</p>

              <p style={styles.lyrics}>Rejoice so highly favoured,</p>
              <p style={styles.lyrics}>The Lord, He is with you,</p>
              <p style={styles.lyrics}>By power of the Spirit,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Let it be, through life for me.</p>

              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>All yours ....</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_TB[1]} title={TITLES_TB[1]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_TB[2]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>(Broken Wings)</p>

              <p style={styles.lyrics}>No, I never knew what love is</p>
              <p style={styles.lyrics}>Til you held me through the storm.</p>
              <p style={styles.lyrics}>When l sank in dreadful darkness,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>My soul crushed, lost, forlorn.</p>

              <p style={styles.lyrics}>My friend, my true friend.</p>
              <p style={styles.lyrics}>Heal me.</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Lead me through the storm.</p>

              <p style={styles.lyrics}>Your hands they reached in pity,</p>
              <p style={styles.lyrics}>Though you could not reason why.</p>
              <p style={styles.lyrics}>My pain it shook your body,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>It hurt to see you cry.</p>

              <p style={styles.lyrics}>My friend, my true friend.</p>
              <p style={styles.lyrics}>Heal me.</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Lead me through the storm.</p>

              <p style={styles.lyrics}>The truth you spoke to my heart.</p>
              <p style={styles.lyrics}>My tears now filled with joy.</p>
              <p style={styles.lyrics}>The love of Jesus touched me</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>O&apos; Happy, blessed day.</p>

              <p style={styles.lyrics}>Cannot chart this course I travel.</p>
              <p style={styles.lyrics}>There are no stars to guide.</p>
              <p style={styles.lyrics}>In my breast a beacon&apos;s burning.</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Angels speak and you I find.</p>

              <p style={styles.lyrics}>My friend, my true friend.</p>
              <p style={styles.lyrics}>Heal me.</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Lead me through the storm.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_TB[2]} title={TITLES_TB[2]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_TB[3]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p style={styles.marginB20}>&apos;The man who hears the word and understands it, he is the one who yields a harvest&apos;. Mt 13:23</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>( Psalm 67 )</p>

              <p style={styles.lyrics}>O God be Gracious and bless us,</p>
              <p style={styles.lyrics}>Let your face shine its light on us</p>
              <p style={styles.lyrics}>May your ways be known on earth</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>And all the peoples praise you.</p>

              <p style={styles.lyrics}>Al-le. Al-le, Alleluia ! (x 3)</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Let all the people praise you!</p>

              <p style={styles.lyrics}>Let all the people be glad and sing.</p>
              <p style={styles.lyrics}>With tender love you rule as king.</p>
              <p style={styles.lyrics}>Your Holy Spirit still lead us on.</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Your peace be with your people.</p>

              <p style={styles.lyrics}>Our work on earth it yields its fruit.</p>
              <p style={styles.lyrics}>The Holy One has blessed us.</p>
              <p style={styles.lyrics}>May God still bless and sanctify,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>&apos;Til all the peoples praise Him.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_TB[3]} title={TITLES_TB[3]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_TB[4]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>A Love song.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_TB[4]} title={TITLES_TB[4]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_TB[5]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p style={styles.lyrics}>I got up when the sun went down</p>
              <p style={styles.lyrics}>And the moon rose bright and high</p>
              <p style={styles.lyrics}>To a pretty girl up the mountain pass</p>
              <p style={styles.lyrics}>Til break of dawn came by.</p>
              <p style={styles.lyrics}>She held my hand.</p>
              <p style={styles.lyrics}>She took my heart</p>
              <p style={styles.lyrics}>And flew away, away.</p>
              <p style={styles.lyrics}>So Lost in dreams.</p>
              <p style={styles.lyrics}>So tossed and turned</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>By the blue eyed butterfly</p>

              <p style={styles.lyrics}>She says the paths of love ain&apos;t true</p>
              <p style={styles.lyrics}>I know, I know, I know.</p>
              <p style={styles.lyrics}>So l hid my head in the blackest cave</p>
              <p style={styles.lyrics}>In the dark of the valley &apos;O.</p>
              <p style={styles.lyrics}>Now you love me more than you did before</p>
              <p style={styles.lyrics}>Your fickle heart stands true</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>I fall down low upon my knee and give myself to you.</p>

              <p style={styles.lyrics}>Come fly with me into the flames</p>
              <p style={styles.lyrics}>Of flickering candlelight,</p>
              <p style={styles.lyrics}>Let all the hurt that we have done</p>
              <p style={styles.lyrics}>Consume there in the night.</p>
              <p style={styles.lyrics}>Her ring is in the river</p>
              <p style={styles.lyrics}>In the wake of our farewell.</p>
              <p style={styles.lyrics}>Wish l recalled a wise man&apos;s words</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>&apos;Son don&apos;t give all your heart&apos;.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_TB[5]} title={TITLES_TB[5]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_TB[6]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>(The wound)</p>

              <p style={styles.lyrics}>Say not a word upon our parting,</p>
              <p style={styles.lyrics}>Lift not your head,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>nor wave to bade goodbye.</p>

              <p style={styles.lyrics}>Just leave</p>
              <p style={styles.lyrics}>and you</p>
              <p style={styles.lyrics}>so soon shall forget me</p>
              <p style={styles.lyrics}>Goodbye, goodbye my love goodbye,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Goodbye, goodbye my love don&apos;t cry.</p>

              <p style={styles.lyrics}>Deep sorrow that love lies forsaken,</p>
              <p style={styles.lyrics}>Cry no more,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Don&apos;t weep for me.</p>

              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Just leave.....</p>

              <p style={styles.lyrics}>A heart so full of love now broken,</p>
              <p style={styles.lyrics}>Pity Christ</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>this painful memory.</p>

              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Just leave.....</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_TB[6]} title={TITLES_TB[6]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_TB[7]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p style={styles.lyrics}>There is no happiness like that in heaven,</p>
              <p style={styles.lyrics}>there is no sadness like that in hell,</p>
              <p style={styles.lyrics}>and such extremes as these</p>
              <p style={styles.lyrics}>this world shall never know</p>
              <p style={styles.lyrics}>&apos;til death,</p>
              <p style={styles.lyrics}>the dark avenger strikes</p>
              <p style={styles.lyrics}>and then</p>
              <p style={styles.lyrics}>the blood of the lamb wherein we&apos;re washed,</p>
              <p style={styles.lyrics}>restores man to God</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>as promised.</p>

              <p style={styles.lyrics}>The promise.</p>
              <p style={styles.lyrics}>His promise,</p>
              <p style={styles.lyrics}>to the ages,</p>
              <p style={styles.lyrics}>long before</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>and since.</p>

              <p style={styles.lyrics}>And how,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>in the completeness of time,</p>

              <p style={styles.lyrics}>His Son,</p>
              <p style={styles.lyrics}>The Christ,</p>
              <p style={styles.lyrics}>was for our sake,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>hung up high.</p>

              <p style={styles.lyrics}>There,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>a king to be watched.</p>

              <p style={styles.lyrics}>Pierced</p>
              <p style={styles.lyrics}>hands,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>feet and side.</p>

              <p style={styles.lyrics}>Open wounds</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>caress the body of a tree.</p>

              <p style={styles.lyrics}>Axed,</p>
              <p style={styles.lyrics}>stripped,</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>cured and sapless.</p>

              <p style={styles.lyrics}>Flesh of blood.</p>
              <p style={styles.lyrics}>Skin of wood.</p>
              <p style={styles.lyrics}>Soft bones and woodgrain,</p>
              <p style={styles.lyrics}>together,</p>
              <p style={styles.lyrics}>bared and counted</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>one,</p>

              <p style={styles.lyrics}>as God is now</p>
              <p style={styles.lyrics}>through the cross</p>
              <p style={styles.lyrics}>with man</p>
              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>forever.</p>

              <p style={{ ...styles.lyrics, ...styles.marginB20 }}>Amen</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_TB[7]} title={TITLES_TB[7]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.buyCds}>
            {t('buyAlbums')}
          </div>
          <div style={styles.cdWrapper}>
            <a href={AMAZON_TB} rel={REL} target={BLANK} style={styles.imageLink}>
              <Image src={Amazon} alt="Amazon" style={styles.image} />
            </a>
            <a href={CDBABY_TB} rel={REL} target={BLANK} style={styles.imageLink}>
              <Image src={CdBaby} alt="CdBaby" style={styles.image} />
            </a>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default TheButterfly
