import React from 'react'
import ReactDOM from 'react-dom'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'controller/store'
import TermsConditions from 'scenes/TermsConditions'
import PrivacyPolicy from 'scenes/PrivacyPolicy'
import CookiePolicy from 'scenes/CookiePolicy'
import Homepage from 'scenes/Homepage'
import Discography from 'scenes/Discography'
import FarewellToDerry from 'scenes/FarewellToDerry'
import TheButterfly from 'scenes/TheButterfly'
import Videos from 'scenes/Videos'
import Books from 'scenes/Books'
import Contacts from 'scenes/Contacts'
import Header from 'components/Header'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import { CookieBar } from 'common/legal'
import {
  LINK_HOMEPAGE, LINK_PRIVACY, LINK_TERMS, LINK_COOKIE,
  LINK_DISCO, LINK_VIDEO,
  LINK_CONTACTS, LINK_BOOKS,
  LINK_FAREWELL, LINK_BUTTERFLY
} from 'controller/constants'
import 'semantic-ui-css/semantic.min.css'
import { styles } from './styles'
import './index.css'
import './i18n'

// to view the state tree in the console
window.store = store

const provider = (
  <Provider store={store}>
    <BrowserRouter>
      <div style={styles.container}>
        <Header />
        <Navbar />
        <Switch>
          <Route exact path={LINK_HOMEPAGE} component={Homepage} />
          <Route exact path={LINK_DISCO} component={Discography} />
          <Route exact path={LINK_DISCO + LINK_FAREWELL} component={FarewellToDerry} />
          <Route exact path={LINK_DISCO + LINK_BUTTERFLY} component={TheButterfly} />
          <Route exact path={LINK_VIDEO} component={Videos} />
          <Route exact path={LINK_BOOKS} component={Books} />
          <Route exact path={LINK_CONTACTS} component={Contacts} />
          <Route exact path={LINK_PRIVACY} component={PrivacyPolicy} />
          <Route exact path={LINK_TERMS} component={TermsConditions} />
          <Route exact path={LINK_COOKIE} component={CookiePolicy} />
        </Switch>
        <Footer />
        <CookieBar />
      </div>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(provider, document.getElementById('root'))
