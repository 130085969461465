import { RED, BLUE } from 'styles/colors'

export const styles = {
  container: {
    width: '100%'
  },
  internalContainer: {
    width: '100%',
    maxWidth: '961px',
    margin: 'auto',
    padding: 30,
  },
  title: {
    fontSize: 33,
    lineHeight: '40px',
    fontWeight: 700,
    marginTop: 20,
    textAlign: 'center',
    color: RED,
    display: 'block'
  },
  videoTitle: {
    fontSize: 24,
    lineHeight: '40px',
    fontWeight: 700,
    marginTop: 50,
    marginBottom: 30,
    textAlign: 'center',
    color: BLUE,
    display: 'block'
  },
  video: {
    display: 'flex',
    // alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 80
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: 15,
    height: 0,
    marginBottom: 20
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%'
  }
}
