import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'
import { Image } from 'semantic-ui-react'
import Fade from 'react-reveal/Fade'
import Amazon from 'assets/img/amazon_black.png'
import {
  LINK_DISCO, LINK_FAREWELL, BLANK, REL, AMAZON_BOOK
} from 'controller/constants'
import { styles } from './styles'

const Books = () => {
  const { t } = useTranslation()

  return (
    <div style={styles.container}>
      <div style={styles.internalContainer}>
        <Fade delay={300}>
          <p style={styles.title}>{t('books')}</p>
        </Fade>

        <Fade delay={300}>
          <div style={styles.bookTitle}>
Farewell To Derry
          </div>
          <div style={styles.bookWrapper}>
            <div style={styles.cdRetro}>
              <p>
Lyrics and music sheets from the album
                {' '}
                <Link to={LINK_DISCO + LINK_FAREWELL}>Farewell to Derry</Link>
              </p>
              <p>It&apos;s not always easy to forget the sod from which you sprung; whoever you are and wherever you are from, whether it be Derry or Gdansk.</p>
              <p>Only the experience of exile can evoke such deep longing for a homeland.</p>
              <p>Some of our finest Irish songs address this theme.</p>
              <p>The older the emigrant, the call of home and boyhood days often seems to pull more reflectively and romantically on the memory and heartstrings.</p>
              <p>Although the main character in the title track emigrated at seventeen, my uncle Sean first left for Scotland at fourteen.</p>
              <p>My other uncles Tommy and Hugh also emigrated for want of work and spent a lifetime in the Liverpool area.</p>
              <p>In regard to working in &apos;steel, sweat and fire&apos; as described in the song, as uncle Charles died in a blast furnace incident in Glasgow.</p>
              <p>This song is dedicated to them and the countless others who said &apos;Farewell to Derry&apos;.</p>
              <p>DISCLAIMER: Book sold as digital copy, no CD enclosed</p>
            </div>
            <div style={styles.cdFront}>
              <div style={styles.buyCds}>
                {t('buyBook')}
              </div>
              <a href={AMAZON_BOOK} rel={REL} target={BLANK} style={styles.imageLink}>
                <Image src={Amazon} alt="Amazon" style={styles.image} />
              </a>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default withRouter(Books)
