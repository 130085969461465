import backgroundImage from 'assets/img/mountains-padraig.jpg'

export const styles = {
  container: {
    paddingTop: 120,
    width: '100%',
    height: '100%',
    background: `linear-gradient(rgba(255,255,255,0.5),rgba(255,255,255,0.5)), url(${backgroundImage})`,
    minHeight: '100vh',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: 'cover'
  }
}
