/* eslint max-len: 0 */
import React from 'react'
import {
  COMPANY, MAILTO, LAST_TERMS_UPDATE, ADDRESS, DATA_PROTECTION_NUMBER, COMPANY_NUMBER
} from '../constants'
import { styles } from '../styles'

const Terms = () => (
  <div style={styles.container}>
    <div style={styles.internalContainer}>
      <div style={styles.textContainer}>
        <p style={styles.title}>TERMS & CONDITIONS</p>

        <div style={styles.paragraphTitle}>1. Introduction</div>
        <div style={styles.paragraphText}>These Usage Terms together with our Privacy Policy and Cookie Policy govern your use of this website and related services (the &quot;Website&quot;); by using the Website, you agree to these terms in full. </div>
        <div style={styles.paragraphText}>If you disagree with these terms of use or any part of these terms of use, please leave with this Website immediately.</div>
        <div style={styles.paragraphText}>Our Website uses cookies. By using our Website and agreeing to these terms of use, you consent to our use of cookies.</div>
        <div style={styles.paragraphText}>We reserve the right to make changes to these usage terms in future and any changes will be posted to the site and will take effect immediately. </div>
        <div style={styles.paragraphText}>Please ensure that you read the terms each time you access this Website.</div>

        <div style={styles.paragraphTitle}>2. Licence to use Website</div>
        <div style={styles.paragraphText}>Unless otherwise stated, we or our licensors own the intellectual property rights in the Website and material on the Website. Subject to the licence below, all these intellectual property rights are reserved. You may view, download for caching purposes only, and print pages from the Website for your own personal use, subject to the restrictions set out below and elsewhere in these terms of use.</div>
        <div style={styles.paragraphText}>You must not:</div>
        <div style={styles.paragraphText}>&#8226; republish material from this Website (including republication on another website);</div>
        <div style={styles.paragraphText}>&#8226; sell, rent or sub-license material from the Website;</div>
        <div style={styles.paragraphText}>&#8226; show any material from the Website in public;</div>
        <div style={styles.paragraphText}>&#8226; reproduce, duplicate, copy or otherwise exploit material on our Website for a commercial purpose;</div>
        <div style={styles.paragraphText}>&#8226; edit or otherwise modify any material on the Website; or</div>
        <div style={styles.paragraphText}>&#8226; redistribute material from this Website except for content specifically and expressly made available for redistribution (such as our newsletter).</div>

        <div style={styles.paragraphTitle}>3.Acceptable use</div>
        <div style={styles.paragraphText}>You must not use our Website in any way that causes, or may cause, damage to the Website or impairment of the availability or accessibility of the Website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</div>
        <div style={styles.paragraphText}>You must not use our Website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</div>
        <div style={styles.paragraphText}>You must not conduct any systematic or automated data collection activities (including, without limitation, scraping, data mining, data extraction and data harvesting) on or in relation to our Website without our express written consent.</div>
        <div style={styles.paragraphText}>You must not use our Website for any purposes related to marketing without our express written consent.</div>

        <div style={styles.paragraphTitle}>4. Restricted access</div>
        <div style={styles.paragraphText}>Access to certain areas of our Website is restricted. We reserve the right to restrict access to other areas of our Website, or indeed our whole Website, at our discretion.</div>
        <div style={styles.paragraphText}>If we provide you with a user ID and password to enable you to access restricted areas of our Website or other content or services, you must ensure that the password is kept confidential.</div>
        <div style={styles.paragraphText}>You must notify us in writing immediately if you become aware of any unauthorised use of your account or password.</div>
        <div style={styles.paragraphText}>You are responsible for any activity on our Website arising out of any failure to keep your password confidential and may be held liable for any losses arising out of such a failure.</div>
        <div style={styles.paragraphText}>We may disable your user ID and password at any time in our sole discretion with or without notice or explanation.</div>

        <div style={styles.paragraphTitle}>5. User content</div>
        <div style={styles.paragraphText}>In these terms of use, “your content” means material (including, without limitation, text, images, audio material, video material and audio-visual material) that you submit to our Website, for whatever purpose.</div>
        <div style={styles.paragraphText}>You grant to us a worldwide, irrevocable, non-exclusive, royalty-free licence to use, reproduce, adapt, publish, translate and distribute your content in any existing or future media.</div>
        <div style={styles.paragraphText}>You also grant to us the right to sub-license these rights and the right to bring an action for infringement of these rights.</div>
        <div style={styles.paragraphText}>You warrant and represent that your content will comply with these terms of use.</div>
        <div style={styles.paragraphText}>Your content must not be illegal or unlawful, must not infringe any third party’s legal rights and must not be capable of giving rise to legal action whether against you or us or a third party (in each case under any applicable law).</div>
        <div style={styles.paragraphText}>You must not submit any content to the Website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.</div>
        <div style={styles.paragraphText}>We reserve the right to edit or remove any material submitted to our Website, or stored on our servers, or hosted or published upon our Website.</div>
        <div style={styles.paragraphText}>Notwithstanding our rights under these terms of use in relation to your content, we do not undertake to monitor the submission of such content to, or the publication of such content on, our Website.</div>

        <div style={styles.paragraphTitle}>6. Limited warranties</div>
        <div style={styles.paragraphText}>We do not warrant the completeness or accuracy of the information published on this Website; nor do we commit to ensuring that the Website remains available or that the material on the Website is kept up to date.</div>
        <div style={styles.paragraphText}>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to this Website and the use of this Website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).</div>
        <div style={styles.paragraphText}>7. Limitations and exclusions of liability</div>
        <div style={styles.paragraphText}>To the extent that the Website and the information and services on the Website are provided free of charge, we will not be liable for any loss or damage of any nature.</div>
        <div style={styles.paragraphText}>We will not be liable to you in respect of any losses arising out of any event or events beyond our reasonable control.</div>
        <div style={styles.paragraphText}>We will not be liable to you in respect of any business losses, including (without limitation) loss of or damage to profits, income, revenue, use, production, anticipated savings, business, contracts, commercial opportunities or goodwill.</div>
        <div style={styles.paragraphText}>We will not be liable to you in respect of any loss or corruption of any data, database or software.</div>
        <div style={styles.paragraphText}>We will not be liable to you in respect of any special, indirect or consequential loss or damage.</div>

        <div style={styles.paragraphTitle}>8. Indemnity</div>
        <div style={styles.paragraphText}>You hereby indemnify us and undertake to keep us indemnified against any losses, damages, costs, liabilities and expenses (including, without limitation, legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute on the advice of our legal advisers) incurred or suffered by us arising out of any breach by you of any provision of these terms of use, or arising out of any claim that you have breached any provision of these terms of use.</div>

        <div style={styles.paragraphTitle}>9. Breaches of these terms of use</div>
        <div style={styles.paragraphText}>Without prejudice to our other rights under these terms of use, if you breach these terms of use in any way, we may take such action as we deem appropriate to deal with the breach, including suspending your access to the Website, prohibiting you from accessing the Website, blocking computers using your IP address from accessing the Website, contacting your internet service provider to request that they block your access to the Website and/or bringing court proceedings against you.</div>

        <div style={styles.paragraphTitle}>10. Variation</div>
        <div style={styles.paragraphText}>We may revise these terms of use from time to time. Revised terms of use will apply to the use of our Website from the date of publication of the revised terms of use on our Website.</div>

        <div style={styles.paragraphTitle}>11. Assignment</div>
        <div style={styles.paragraphText}>We may transfer, sub-contract or otherwise deal with our rights and/or obligations under these terms of use without notifying you or obtaining your consent.</div>
        <div style={styles.paragraphText}>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms of use.</div>

        <div style={styles.paragraphTitle}>12. Severability</div>
        <div style={styles.paragraphText}>If a provision of these terms of use is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</div>

        <div style={styles.paragraphTitle}>13. Exclusion of third party rights</div>
        <div style={styles.paragraphText}>These terms of use are for the benefit of you and us, and are not intended to benefit any third party or be enforceable by any third party. The exercise of our and your rights in relation to these terms of use is not subject to the consent of any third party.</div>

        <div style={styles.paragraphTitle}>14. Entire agreement</div>
        <div style={styles.paragraphText}>These terms of use, together with our privacy policy, constitute the entire agreement between you and us in relation to your use of our Website and supersede all previous agreements in respect of your use of our Website.</div>

        <div style={styles.paragraphTitle}>15. Enforcement</div>
        <div style={styles.paragraphText}>Our failure to enforce any term or condition of these Terms of Use shall not be deemed as a waiver of the right to enforce such term or condition.</div>

        <div style={styles.paragraphTitle}>16. Law and jurisdiction</div>
        <div style={styles.paragraphText}>These terms of use will be governed by and construed in accordance with English law, and any disputes relating to these terms of use will be subject to the exclusive jurisdiction of the courts of England and Wales.</div>

        <div style={styles.paragraphTitle}>17. Our details</div>
        <div style={styles.paragraphText}>
The copyright of this Website and all related content belongs to Padraig Lynch.
        </div>
        <div>
            The company managing the website is
          {' '}
          {` ${COMPANY}.`}
        </div>
        <div style={styles.paragraphText}>
You can contact us
          {' '}
          <a href={MAILTO} style={styles.paragraphAnchor} data-cy="terms-link-aiemail" rel="noopener nofollow noreferrer">here.</a>
        </div>

        <div style={styles.lastUpdate}>
        Last updated
          {' '}
          {LAST_TERMS_UPDATE}
        </div>
      </div>
    </div>
  </div>
)

export default Terms
