/* eslint max-len:0 */
export const en = {
  title: 'Singing praises to the Lord',
  subtitle: 'Worship songs and memories of a Welsh-based Irish artist',
  bio1: 'I was born in Derry and as a child grew up during the sixties and seventies in the Creggan area of the westbank of the Foyle.',
  bio2: 'I learned to play the fiddle when I was nine.',
  bio3: 'I always had a passion for Irish folk music and during \'the troubles\' it became a great personal solace and source of entertainment.',
  bio4: 'Most of my friends played a musical instrument and we played daily in each others homes and as we got older played in local bars.',
  bio5: 'Often dodging the bullets and the bombing to get to a gig.',
  bio6: 'My main influences were The Clancy Brothers, The Dubliners, PLanxty and The Bothy band and Christy Moore.',
  bio7: 'Like so many of my generation I left Derry at the height of the violence.',
  bio8: 'Job opportunities were few and I \'left the shamrock shore\'.',
  bio9: 'I always had a notion for writing poetry but as the years went on began to feel the urge to write both lyrics and music.',
  bio10: 'The fact that I picked up the guitar was probably a influential factor.',
  bio11: 'The songs on the albums are a compilation of material that I have written over the past 40 years.',
  bio12: 'It reflects my own experiences, that of my family both immediate and extended, of being in love, unrequited love, thoughts on emigration both present and historical ,drinking songs, lamentation, the desire for peace in a strife torn land and a rage against the futility of war.',

  biography: 'BIOGRAPHY',
  discography: 'DISCOGRAPHY',
  video: 'VIDEO',
  books: 'BOOKS',
  contactsTitle: 'Contacts',
  explore: 'Explore',
  buyAlbums: 'Buy albums or tracks on',
  buyBook: 'Buy this book on'
}
