import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'
import { Image } from 'semantic-ui-react'
import Fade from 'react-reveal/Fade'
import IAmWithYou from 'assets/img/cdtemplate.jpg'
import TheButterfly from 'assets/img/TheButterfly.jpg'
import FarewellToDerry from 'assets/img/FarewellToDerry.jpg'
import Amazon from 'assets/img/amazon_black.png'
import CdBaby from 'assets/img/cdbaby_black.png'
import {
  LINK_FAREWELL, LINK_BUTTERFLY, LINK_DISCO, BLANK, REL, AMAZON, CDBABY
} from 'controller/constants'
import { styles } from './styles'

const Discography = () => {
  const { t } = useTranslation()

  return (
    <div style={styles.container}>
      <div style={styles.internalContainer}>
        <Fade delay={300}>
          <p style={styles.title}>{t('discography')}</p>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
I am with You
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <Image src={IAmWithYou} alt="I am with You" style={styles.cover} />
            </div>
            <div style={styles.cdRetro}>
              Work in progress
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
The Butterfly
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <Image src={TheButterfly} alt="The Butterfly" style={styles.cover} />
            </div>
            <div style={styles.cdRetro}>
              <p>This acoustic album of hope and joy reflects on God&apos;s love for each one of us.</p>
              <p>It&apos;s the result of a long spiritual journey to truly have a loving heart-focused relationship with God.</p>
              <div style={styles.btnWrapper}>
                <Link to={LINK_DISCO + LINK_BUTTERFLY} style={styles.btn}>{t('explore')}</Link>
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
Farewell To Derry
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <Image src={FarewellToDerry} alt="Farewell To Derry" style={styles.cover} />
            </div>
            <div style={styles.cdRetro}>
              <p>This album draws deeply from Ireland&apos;s rich tradition of music and ballads.</p>
              <p>It reflects the experience of growing up in Derry with themes on emigration,love, injustice and the longing for peace.</p>
              <div style={styles.btnWrapper}>
                <Link to={LINK_DISCO + LINK_FAREWELL} style={styles.btn}>{t('explore')}</Link>
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.buyCds}>
            {t('buyAlbums')}
          </div>
          <div style={styles.cdWrapper}>
            <a href={AMAZON} rel={REL} target={BLANK} style={styles.imageLink}>
              <Image src={Amazon} alt="Amazon" style={styles.image} />
            </a>
            <a href={CDBABY} rel={REL} target={BLANK} style={styles.imageLink}>
              <Image src={CdBaby} alt="CdBaby" style={styles.image} />
            </a>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default withRouter(Discography)
