import React from 'react'
import { Table } from 'semantic-ui-react'
import {
  ALL_ABOUT_COOKIES, LAST_COOKIE_UPDATE,
  ABOUT_COOKIES, GA_OPTOUT, YOUTUBE_COOKIES_WEB,
  GOOGLE_COOKIES_WEB_1, GOOGLE_COOKIES_WEB_2, COOKIE_COOKIES_ON
} from 'common/legal/constants'
import { styles } from '../styles'

const Cookies = () => (
  <div style={styles.container}>
    <div style={styles.internalContainer}>
      <div style={styles.textContainer}>
        <p style={styles.title}>COOKIE POLICY</p>

        <div style={styles.paragraphText}>
          Use of this website and related services (the &quot;Website&quot;)
          by you constitutes acceptance of this cookie policy (&quot;Cookie Policy&quot;).
          If you do not wish to accept this Cookie Policy (&quot;Policy&quot;)
          please leave this Website immediately.
        </div>
        <div style={styles.paragraphText}>
          We may make changes to this Policy from time to time.
          If we do so, we will post the changes on this page and they will apply from the time we post them.
          Individuals should check back frequently to see any updates or changes to this Policy.
          Older versions will not be available after Policy update.
        </div>

        <div style={styles.paragraphTitle}>1. Introduction</div>
        <div style={styles.paragraphText}>
      Cookies are small text files containing information (a string of letters and numbers)
      that are placed on your browser by websites that you visit.
        </div>
        <div style={styles.paragraphText}>
      This information might be about you, your preferences or your device
      and is mostly used to make the site work as you expect it to or work more efficiently.
      The information does not usually directly identify you,
        </div>
        <div style={styles.paragraphText}>
      but it can give you a more personalised web experience.
        </div>
        <div style={styles.paragraphText}>
      Because we respect your right to privacy, you can choose not to allow some types of cookies.
        </div>
        <div style={styles.paragraphText}>
      Click on the different category headings to find out more and change our default settings.
        </div>
        <div style={styles.paragraphText}>
      However, blocking some types of cookies may impact your experience of the Website
      and the services we are able to offer.
        </div>

        <div style={styles.paragraphTitle}>2. Our Cookies</div>
        <div style={styles.paragraphText}>
      Below is a list of the cookies we use on this Website.
      We update the following list of cookies whenever new cookies
      are added to our Website.
        </div>

        <div style={styles.paragraphSubTitle}>2.1 Strictly necessary cookies</div>
        <div style={styles.paragraphText}>
    These cookies are necessary for the website to function
    and cannot be switched off in our systems.
          {' '}
    They are usually only set in response to actions made by you
    which amount to a request for services, such as setting your privacy preferences
    and logging in.
          {' '}
    You can set your browser to block or alert you about these cookies,
    but some parts of the site will not then work.
          {' '}
    These cookies do not store any personally identifiable information.
        </div>

        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Cookie Provider</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Expiration</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Engiweb</Table.Cell>
              <Table.Cell>{COOKIE_COOKIES_ON}</Table.Cell>
              <Table.Cell>
This cookie is associated with a free script
          which shows a cookie alert notice on a website.
                {' '}
          It is used to record when the user has dismissed the notice
          to prevent it re-appearing on return visits.
              </Table.Cell>
              <Table.Cell>12 months</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <div style={styles.paragraphSubTitle}>2.2 Performance cookies</div>
        <div style={styles.paragraphText}>
      These cookies allow us to count visits and traffic sources
      so we can measure and improve the performance of our site.
      They help us to know which pages are the most and least popular
      and see how visitors move around the site.
      All information these cookies collect is aggregated and therefore anonymous.
      If you do not allow these cookies we will not know
      when you have visited our site, and will not be able to monitor its performance.
        </div>

        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Cookie Provider</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Expiration</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Google Analytics</Table.Cell>
              <Table.Cell>_ga _gid _gat</Table.Cell>
              <Table.Cell>
            These cookies are used to collect information
            about how visitors use our Website.
                {' '}
                {' '}
We use the information to compile reports
            and to help us improve the website.
                {' '}
                {' '}
The cookies collect information
            in an anonymous form, including the number of visitors to the Website,
            where visitors have come to the Website from and the pages they visited.
                {' '}
            _ga, _gid: Google uses these cookies to distinguish users.
                {' '}
            _gat: Google uses this cookie to throttle request rate.
                {' '}
            To find out more about Google Analytics Cookie Usage on Websites
            and Google Analytics security and privacy principles, visit
                {' '}
                <a
                  style={styles.paragraphAnchor}
                  href={GOOGLE_COOKIES_WEB_1}
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  data-cy="ga-web-1"
                >
                  {GOOGLE_COOKIES_WEB_1}
                </a>
            and
                {' '}
                <a
                  style={styles.paragraphAnchor}
                  href={GOOGLE_COOKIES_WEB_2}
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  data-cy="ga-web-1"
                >
                  {GOOGLE_COOKIES_WEB_2}
                </a>
              </Table.Cell>
              <Table.Cell>
_ga: 2 years
                <br />
_gid: 24 hours
                <br />
_gat: 1 minute
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Youtube / Google</Table.Cell>
              <Table.Cell>
CONSENT APISID SID NID HSID SAPISID
            SIDCC SSID VISITOR_INFO1_LIVE PREF
              </Table.Cell>
              <Table.Cell>
            These Youtube / Google cookies are used by both the videos
            embedded into our Website (from our official Youtube channel).
                {' '}
            Embedded videos use YouTube’s privacy-enhanced mode.
                {' '}
            This mode may set cookies on your computer once you click
            on the YouTube video player, but YouTube will not store
            personally-identifiable cookie information for playbacks
            of embedded videos using the privacy-enhanced mode.
                {' '}
            CONSENT: Google use this cookie to get information about the country
            and the consent given by the user to using cookies.
                {' '}
            APISID, NID, SID: Google uses these cookies,
            based on recent searches and interactions,
            to customise ads on Google websites.
                {' '}
            HSID, SSID, APISID en SAPISID: ensure that Google can collect
            user information for videos hosted by YouTube.
                {' '}
            VISITOR_INFO1_LIVE, PREF: Google uses these cookies to collect
            visitor information and custom preferences from embedded Youtube videos.
            To find out more, visit
                {' '}
                <a
                  style={styles.paragraphAnchor}
                  href={YOUTUBE_COOKIES_WEB}
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  data-cy="ga-web-1"
                >
                  {YOUTUBE_COOKIES_WEB}
                </a>
                {' '}
and
                {' '}
                <a
                  style={styles.paragraphAnchor}
                  href={GOOGLE_COOKIES_WEB_2}
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  data-cy="ga-web-1"
                >
                  {GOOGLE_COOKIES_WEB_2}
                </a>
              </Table.Cell>
              <Table.Cell>Most of these cookies expire within 12 months</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>


        <div style={styles.paragraphTitle}>3. How to change cookie settings</div>
        <div style={styles.paragraphText}>
      Most web browsers allow some control of most cookies
      through the browser settings. To find out more about cookies,
      including how to see what cookies have been set and how to manage
      and delete them, visit
          {' '}
          <a
            style={styles.paragraphAnchor}
            href={ABOUT_COOKIES}
            target="_blank"
            rel="noopener nofollow noreferrer"
            data-cy="about-cookies"
          >
            {ABOUT_COOKIES}
          </a>
          {' '}
or
          {' '}
          <a
            style={styles.paragraphAnchor}
            href={ALL_ABOUT_COOKIES}
            target="_blank"
            rel="noopener nofollow noreferrer"
            data-cy="all-about-cookies"
          >
            {ALL_ABOUT_COOKIES}
          </a>
.
      To opt out of being tracked by Google Analytics across all websites
      visit
          {' '}
          <a
            style={styles.paragraphAnchor}
            href={GA_OPTOUT}
            target="_blank"
            rel="noopener nofollow noreferrer"
            data-cy="ga-optout"
          >
            {GA_OPTOUT}
          </a>
      .
      You can also use our cookie tool by clicking the COOKIES button
      in the top navigation bar. This tool allows users to more easily change
      their cookie settings after their initial choice.
        </div>

        <div style={styles.lastUpdate}>
            Last updated
          {' '}
          {LAST_COOKIE_UPDATE}
        </div>
      </div>
    </div>
  </div>
)

export default Cookies
