/* eslint max-len:0 */

// Routes
export const LINK_HOMEPAGE = '/'
export const LINK_PRIVACY = '/privacy-policy'
export const LINK_TERMS = '/terms-conditions'
export const LINK_CONTACTS = '/contacts'
export const LINK_COOKIE = '/cookie-policy'
export const LINK_VIDEO = '/video'
export const LINK_DISCO = '/disco'
export const LINK_BUTTERFLY = '/the-butterfly'
export const LINK_FAREWELL = '/farewell-to-derry'
export const LINK_BOOKS = '/books'
export const UPDATE_COOKIE_VALUE = 'UPDATE_COOKIE_VALUE'
export const SHOW_COOKIE_BAR = 'SHOW_COOKIE_BAR'
export const TOGGLE_COOKIE = 'TOGGLE_COOKIE'
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'
export const BLANK = '_blank'
export const REL = 'noopener noreferrer nofollow'
export const AMAZON = 'https://www.amazon.co.uk/s?k=padraig+lynch'
export const AMAZON_FTD = 'https://www.amazon.co.uk/Farewell-Derry-Padraig-Lynch/dp/B0038AMWW8'
export const AMAZON_TB = 'https://www.amazon.co.uk/Butterfly-Padraig-Lynch/dp/B007I7GM5A'
export const CDBABY_FTD = 'https://store.cdbaby.com/cd/PadraigLynch'
export const CDBABY_TB = 'https://store.cdbaby.com/cd/padraiglynch2'
export const AMAZON_BOOK = 'https://www.amazon.co.uk/Farewell-Derry-Padraig-Lynch/dp/B0038AMWW8'
export const CDBABY = 'https://store.cdbaby.com/Artist/PadraigLynch'
export const YOUTUBE_NOCOOKIE = 'https://www.youtube-nocookie.com/embed/'

export const TITLES_FTD = [
  '01 - Farewell to Derry',
  '02 - Let the children sing',
  '03 - May Blossoms',
  '04 - The Forge',
  '05 - Jack the Rover',
  '06 - Take Your Burden to the Lord',
  '07 - The Hirelings',
  '08 - Forever Present love',
  '09 - Kathy\'s Song',
  '10 - The star of the Morning',
  '11 - The Mullenan Step',
  '12 - The Patriotic Joke'
]

export const VIDEOS_FTD = [
  'E15mFIPERx0',
  'o3gRkS8AgJc',
  'zRDQR3vqTiA',
  '',
  '',
  '351htUIggzs',
  'eMAdOQz5LL0',
  'DBL4q-Wh-f4',
  'mGNrXNeBxPM',
  '_kowaSCRp18',
  '',
  ''
]

export const TITLES_TB = [
  '01 - The Bridal Song',
  '02 - The Rose',
  '03 - Desolation',
  '04 - The Harvest Song',
  '05 - Dance of the Butterfly',
  '06 - The Butterfly',
  '07 - Elide',
  '08 - The Wood of the Cross'
]

export const VIDEOS_TB = [
  '5UIzdXhmYEw',
  'AigL5eRfsN4',
  'Cu5t7VsC_Ng',
  'wCZHT6Z_Ea0',
  'V70ZSNDmwHQ',
  '8heCRvGmM9E',
  'J0sA9OCTrlE',
  'mjTX-uMcA7c'
]

export const VIDEOS = [
  {
    title: 'The Holy Rosary. The Glorious Mysteries. Walsingham',
    youtubeCode: 'eFDbnIog-FA',
    description: 'Prayed in the meadows along the "Holy Mile" from the Shrine of the "Slipper Chapel" towards the Shrine in the grounds of the Abbey in Walsingham. The Virgin Mary appeared there in the year 1061. It is known as England\'s Nazareth.'
  },
  {
    title: 'The Holy Rosary. The Sorrowful Mysteries',
    youtubeCode: '7h0sXGIVS-o',
    description: 'Gospel Rosary. Holy Week. The Passion of Jesus Christ. Tuesday and Friday.'
  },
  {
    title: 'Medjugorje Testimony',
    youtubeCode: '8biTLU2iJqM',
    description: 'Called to Medjugorje - 14th to 21st September 2017.'
  },
  {
    title: 'Rosary with Scripture: Joyful Mystery',
    youtubeCode: '4MznNHmJ1fk',
    description: 'Rosary at night at St James\'s Church, Medjugorje.'
  },
  {
    title: 'Medjugorje: Sunrise on Holy Mountain',
    youtubeCode: '-ch0FDfh6Mk',
    description: 'Climbing the Mountain before dawn with John and Margaret.'
  },
  {
    title: 'Medjugorje',
    youtubeCode: 'Dyjia7kRHes',
    description: 'Outside St James\'s Church.'
  },
  {
    title: 'lsaiah 56,6: "These l will bring to my Holy Mountain"',
    youtubeCode: 'cNB0xAXtIaw',
    description: 'Medjugorje, September 16th 2017. Climbing before dawn. Isaiah 65, 17-25.'
  }
]
