import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import {
  LINK_HOMEPAGE, LINK_VIDEO,
  LINK_DISCO, LINK_CONTACTS, LINK_BOOKS
} from 'controller/constants'
import { styles } from './styles'

const Navbar = ({ activePath }) => (
  <div style={styles.container} data-cy="header">
    <div style={styles.subcontainer}>
      <Link
        to={LINK_HOMEPAGE}
        style={
          activePath === LINK_HOMEPAGE
            ? styles.navlinkActive : styles.navlink}
      >
        <Icon
          name="star"
          style={
            activePath === LINK_HOMEPAGE
              ? styles.iconActive : styles.icon}
        />
      </Link>
      <Link
        to={LINK_DISCO}
        style={
          activePath.indexOf(LINK_DISCO) > -1
            ? styles.navlinkActive : styles.navlink}
      >
        <Icon
          name="music"
          style={
            activePath.indexOf(LINK_DISCO) > -1
              ? styles.iconActive : styles.icon}
        />
      </Link>
      <Link
        to={LINK_VIDEO}
        style={
          activePath.indexOf(LINK_VIDEO) > -1
            ? styles.navlinkActive : styles.navlink}
      >
        <Icon
          name="video"
          style={
          activePath.indexOf(LINK_VIDEO) > -1
            ? styles.iconActive : styles.icon}
        />
      </Link>
      <Link
        to={LINK_BOOKS}
        style={
          activePath.indexOf(LINK_BOOKS) > -1
            ? styles.navlinkActive : styles.navlink}
      >
        <Icon
          name="book"
          style={
          activePath.indexOf(LINK_BOOKS) > -1
            ? styles.iconActive : styles.icon}
        />
      </Link>
      <Link
        to={LINK_CONTACTS}
        style={
          activePath === LINK_CONTACTS
            ? styles.navlinkActive : styles.navlink}
      >
        <Icon
          className="envelope outline"
          style={
          activePath === LINK_CONTACTS
            ? styles.iconActive : styles.icon}
        />
      </Link>
    </div>
  </div>
)

Navbar.propTypes = {
  activePath: PropTypes.string
}

Navbar.defaultProps = {
  activePath: LINK_HOMEPAGE
}

export default withRouter(Navbar)
