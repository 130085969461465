import React from 'react'
import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import {
  VIDEOS, YOUTUBE_NOCOOKIE
} from 'controller/constants'
import { styles } from './styles'

const Videos = () => {
  const { t } = useTranslation()

  const videos = VIDEOS.map(video => (
    <Fade delay={300}>
      <div style={styles.video}>
        <div style={styles.videoTitle}>
          {video.title}
        </div>
        <div style={styles.videoContainer}>
          <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + video.youtubeCode} title={video.title} frameBorder="0" allowFullScreen />
        </div>
        <div style={styles.description}>
          {video.description}
        </div>
      </div>
    </Fade>
  ))

  return (
    <div style={styles.container}>
      <div style={styles.internalContainer}>
        <Fade delay={300}>
          <p style={styles.title}>{t('video')}</p>
        </Fade>

        {videos}
      </div>
    </div>
  )
}

export default Videos
