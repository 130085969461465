import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'semantic-ui-react'
import Fade from 'react-reveal/Fade'
import uuidv4 from 'uuid/v4'
import { SOCIAL, SOCIAL_LINKS } from '../constants'
import { styles } from './styles'

const Contacts = () => {
  const { t } = useTranslation()

  const getContacts = () => SOCIAL.map((item, index) => {
    return (
      <Fade delay={300} key={`social${uuidv4()}`}>
        <a href={`https://${SOCIAL_LINKS[index]}`} rel="nofollow noreferrer noopener" target="_blank">
          <Icon name={item} style={styles.icon} />
        </a>
      </Fade>
    )
  })

  return (
    <div style={styles.container}>
      <div style={styles.internalContainer}>
        <Fade delay={300}>
          <p style={styles.title}>{t('contactsTitle')}</p>
        </Fade>
        <div style={styles.iconsWrapper}>
          {getContacts()}
        </div>
      </div>
    </div>
  )
}

export default Contacts
