import { BLACK } from 'styles/colors'

export const styles = {
  container: {
    width: '100%',
    maxWidth: 738,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    color: BLACK,
    textAlign: 'center',
  },
  separator: {
    marginRight: 10,
    marginLeft: 10,
  },
  text: {
    color: BLACK
  }
}
