/* eslint max-len:0 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from 'semantic-ui-react'
import Fade from 'react-reveal/Fade'
import Amazon from 'assets/img/amazon_black.png'
import CdBaby from 'assets/img/cdbaby_black.png'
import {
  BLANK, YOUTUBE_NOCOOKIE, REL, AMAZON_FTD, CDBABY_FTD, VIDEOS_FTD, TITLES_FTD
} from 'controller/constants'
import { styles } from './styles'

const FarewellToDerry = () => {
  const { t } = useTranslation()

  return (
    <div style={styles.container}>
      <div style={styles.internalContainer}>
        <Fade delay={300}>
          <p style={styles.title}>Farewell to Derry</p>
        </Fade>

        <Fade delay={300}>
          <div style={styles.buyCds}>
            {t('buyAlbums')}
          </div>
          <div style={styles.cdWrapper}>
            <a href={AMAZON_FTD} rel={REL} target={BLANK} style={styles.imageLink}>
              <Image src={Amazon} alt="Amazon" style={styles.image} />
            </a>
            <a href={CDBABY_FTD} rel={REL} target={BLANK} style={styles.imageLink}>
              <Image src={CdBaby} alt="CdBaby" style={styles.image} />
            </a>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[0]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>It&apos;s not always easy to forget the sod from which you sprung; whoever you are and wherever you are from, whether it be Derry or Gdansk.</p>
              <p>Only the experience of exile can evoke such deep longing for a homeland.</p>
              <p>Some of our finest Irish songs address this theme.</p>
              <p>The older the emigrant, the call of home and boyhood days often seems to pull more reflectively and romantically on the memory and heartstrings.</p>
              <p>Although the main character in the title track emigrated at seventeen, my uncle Sean first left for Scotland at fourteen.</p>
              <p>My other uncles Tommy and Hugh also emigrated for want of work and spent a lifetime in the Liverpool area.</p>
              <p>In regard to working in &apos;steel, sweat and fire&apos; as described in the song, as uncle Charles died in a blast furnace incident in Glasgow.</p>
              <p>This song is dedicated to them and the countless others who said &apos;Farewell to Derry&apos;.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_FTD[0]} title={TITLES_FTD[0]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[1]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>This song was inspired by a longing for peace in a land that has known more than its fair share of troubles.</p>
              <p>In particular, I wrote it at the time of the disturbances and protests around the &apos;Holy Cross&apos; primary school in Belfast.</p>
              <p>The experience was evidently traumatic and distressful for the children, their parents and their pastor Aidan.</p>
              <p>It is a song of joy and of hope for a lasting peace.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_FTD[1]} title={TITLES_FTD[1]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[2]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>This is a song about a lost and unrequited love.</p>
              <p>In spite of the deep sense of loss the poet in the chorus sings:</p>
              <p style={styles.lyrics}>Bring back the summer sunshine.</p>
              <p style={styles.lyrics}>May Blossoms scent the air.</p>
              <p style={styles.lyrics}>Let swallows scent the hillside.</p>
              <p style={styles.lyrics}>And love be everywhere.</p>
              <p>The song displays a deep spiritual side to love and no matter how well a match may be made in heaven the departing lover always has a free will and choice to leave.</p>
              <p>No matter what the angels plead.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_FTD[2]} title={TITLES_FTD[2]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[3]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>The song is about Tomas, my grandfather, who was born in 1875.</p>
              <p>The necessity of the gentry to have a skilled blacksmith, I believe, saved my forefathers from the worst ravages of &apos;The Great Hunger&apos; of 1847-8.</p>
              <p>The famine which lead to the emigration and death of milions.</p>
              <p>The Loughrey family, patrons of the forge, were landowners in the town land of Mullenan and also had a grand stately residence several miles away on the other side of the river Foyle.</p>
              <p>By coincidence, as a youth, I worked for an eldery Miss Loughrey, as her gardener, towards the end of her life.</p>
              <p>Some fifty years after Tomas has shoed her horses.</p>
              <p>She spoke fondly of the forge, of Tomas and her journeys to the forge by horseback.</p>
              <p>As well as being a gifted musician Tomas was gifted with an uncanny ability to communicate with horses.</p>
              <p>Once when a spooked carthorse was running amok in Bishop Street, with its cargo scattering to the wind and the drover panic striken at a safe distance, he boldly and calmly walked up to to it, reached out, stroked it and whispered in its ear.</p>
              <p>Tranquility was restored to the marvel of the bystanders.</p>
              <p>However, his gift with horses had its unfortunate limitations.</p>
              <p>He once attempted to shoe a stallion which turned on him and fatally injured him.</p>
              <p>My father, the eldest son, was too young to learn the trade and the forge was closed.</p>
              <p>So ended a long line of blacksmiths.</p>
            </div>
            <div style={styles.cdRetro} />
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[4]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>The song is a celebration of music, drinking ,dancing, good company, having a few pounds in your pocket and returning home after a long absence for the &apos;craic&apos;.</p>
              <p>The words are set to traditional dance tunes.</p>
            </div>
            <div style={styles.cdRetro} />
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[5]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>We all experience &apos;a dark night of the soul&apos; in our lives.</p>
              <p>I know I sure have in mine.</p>
              <p>It has been my personal faith and a trust in God which has helped me through at these times.</p>
              <p>The song is scripture based and continues to speak to me with a constant freshness.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_FTD[5]} title={TITLES_FTD[5]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[6]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>I was initially moved to write this after reading an article in the Irish Post about a boy and girl who were sold at a &apos;hiring fair&apos;, or slave market, such as the one held in &apos;The Diamond&apos; in Derry until the 1930&apos;s.</p>
              <p>They were seperated and only reunited some sixty years later.</p>
              <p>Lord Culmore, an absentee landlord, has a grand country house and estate in Hereford which I visited some years ago.</p>
              <p>It was arguably built on the backs of an exploited labour force.</p>
              <p>The main character is called Dohert as my foreparents, on my mother&apos;s side of the family, were O&apos;Doherty.</p>
              <p>Furthermore, stories about an uncle Sean, who eloped with his sweetheart to Scotland in search of work brewed and fermented to seal the plot.</p>
              <p>One of his decendants, Chuck, an American, turned up in Derry some eighty years later in search of his roots.</p>
              <p>From Scotland Sean had evidently emigrated to the States.</p>
              <p>The song is dedicated to him, his decendants and all hirelings of yesteryears and our own times.</p>
              <p>Michael McGowan, in his biography, &apos;The hard road to Klondike&apos; ( The Great Wheel of Life ) gives a poignant account of what it was like to be sold at Letterkenny Fair at nine years of age.</p>
              <p>Furthermore, John McLaughlin, in his book &apos;Carrowmenagh&apos; tells of the cruel treatment by landlords, typified in the ruthless evictions in the winter of 1881.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_FTD[6]} title={TITLES_FTD[6]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[7]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>I was first moved to write this romantic song many years ago and it took a long time to come to fruition.</p>
              <p>It is a love song expressing total devotion and the bliss of being deeply and totally in love.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_FTD[7]} title={TITLES_FTD[7]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[8]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>How do you express to a dear friend a sincere sense of compassion for the loss of his beloved daughter.</p>
              <p>I was moved to write this as a way of giving a sense of hope and sharing a belief in their being as one, once more and singing again the songs that they once shared.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_FTD[8]} title={TITLES_FTD[8]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[9]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>I have been truly blessed with the constant love of a woman through &apos;thick and thin&apos;, for better or for worse.</p>
              <p>This song is a tribute to and celebration of enduring love.</p>
            </div>
            <div style={styles.cdRetro}>
              <div style={styles.videoContainer}>
                <iframe style={styles.iframe} src={YOUTUBE_NOCOOKIE + VIDEOS_FTD[9]} title={TITLES_FTD[9]} frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[10]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>This is a short fiddle tune, inspired by the beautiful district after which it is named on the Derry/Donegal border.</p>
              <p>It is the area in which my grandfather had his forge and where he played as a fiddler.</p>
            </div>
            <div style={styles.cdRetro} />
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.cdTitle}>
            {TITLES_FTD[11]}
          </div>
          <div style={styles.cdWrapper}>
            <div style={styles.cdFront}>
              <p>An American friend asked me what it was like growing up in Derry during the &apos;Irish Wars&apos;.</p>
              <p>I replied with my customary soft platitudes, which had enabled me to dodge the truth when asked a similar question before.</p>
              <p>i had learned from my father the keynote to survival during &apos;The Troubles&apos;.</p>
              <p>&apos;Whatever you say, say nothing!&apos;.</p>
              <p>But Rebecca&apos;s father had been tortured as a POW during the Korean War.</p>
              <p>He was not the same person when he returned home and his experience had been concealed by Governmental secrecy.</p>
              <p>She pushed me hard, &apos;the bottle broke&apos;, shattered to smithereens and I told her my truth.</p>
              <p>She thanked me and the song bubbled up from the deep.</p>
            </div>
            <div style={styles.cdRetro} />
          </div>
        </Fade>

        <Fade delay={300}>
          <div style={styles.buyCds}>
            {t('buyAlbums')}
          </div>
          <div style={styles.cdWrapper}>
            <a href={AMAZON_FTD} rel={REL} target={BLANK} style={styles.imageLink}>
              <Image src={Amazon} alt="Amazon" style={styles.image} />
            </a>
            <a href={CDBABY_FTD} rel={REL} target={BLANK} style={styles.imageLink}>
              <Image src={CdBaby} alt="CdBaby" style={styles.image} />
            </a>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default FarewellToDerry
